import React, { useCallback, useState } from "react";
import Modal from "components/shared/modal/Modal";
import ProductIdMapping from "./ProductIdMapping";
import TextButton from "components/shared/button/TextButton";
import { FormProvider, useForm } from "react-hook-form";

export default function ProductIdMappingModal({
  productIdMapping,
  onDone,
  onCancel,
}) {
  const methods = useForm();
  const [currentMappings, setCurrentMappings] = useState(
    !!productIdMapping ? Object.entries(productIdMapping) : null
  );

  const [newMappings, setNewMappings] = useState([]);

  function handleSave(data) {
    const validMappings = data.mappings?.productIds?.filter((e) => !!e?.[0]);

    const newMappings = !!validMappings
      ? Object.fromEntries([...validMappings])
      : null;

    onDone && onDone(newMappings);
  }

  const handleRemove = useCallback(
    (index) => {
      currentMappings.splice(index, 1);

      setCurrentMappings([...currentMappings]);
    },
    [currentMappings]
  );

  const handleNewRemove = useCallback(
    (index) => {
      newMappings.splice(index - 100, 1);

      setNewMappings([...newMappings]);
    },
    [newMappings]
  );

  const handleAddMapping = () => {
    newMappings.push([]);

    setNewMappings([...newMappings]);
  };

  return (
    <Modal onClose={() => onDone()}>
      <Modal.Header title="Product Id Mappings" onClose={() => onCancel()} />
      <Modal.Body hasScroll>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleSave)}
            noValidate
            autoComplete="off"
          ></form>
          {currentMappings?.map((m, index) => (
            <div key={`product-id-mapping-${index}`}>
              <ProductIdMapping
                name={m[0]}
                value={m[1]}
                index={index}
                onRemove={handleRemove}
              />
            </div>
          ))}
          {newMappings?.map((m, index) => (
            <div key={`new-product-id-mapping-${index}`}>
              <ProductIdMapping
                name={m?.[0]}
                value={m?.[1]}
                index={100 + index}
                onRemove={handleNewRemove}
              />
            </div>
          ))}

          <TextButton text="Add" icon="add" onClick={handleAddMapping} />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onCancel()}
        onSubmit={methods.handleSubmit(handleSave)}
        submitText="Save"
      />
    </Modal>
  );
}
