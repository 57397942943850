import React, { useState, useEffect } from "react";
import FormField from "components/shared/formfield/FormField";
import { useFormContext } from "react-hook-form";
import FormFieldCheckbox from "components/shared/form/FormFieldCheckbox";
import FormFieldSelect from "components/FormFieldSelect";
import { billTo } from "data/billToTypes";
import billToValues from "enums/billTo";
import planGroup from "enums/planGroup";
import planTypeData from "data/planTypeData";

const PUBLIC_ASSETS_BASE_URL = process.env?.REACT_APP_PUBLIC_ASSETS_URL;

export default function AttributeValueInputs({ onPlanTypeMappingClick }) {
  const { watch, register, control, errors, setValue } = useFormContext();

  const [isPrisidioBranded, setIsPrisidioBranded] = useState();
  const [allowPublicRegistration, setAllowPublicRegistration] = useState(false);

  const billToValue = watch("billingRule.billTo");

  function handlePlanTypeMappingClick() {
    onPlanTypeMappingClick && onPlanTypeMappingClick();
  }

  function handlePrisidioBrandedClick() {
    setIsPrisidioBranded(!isPrisidioBranded);
  }

  function handleAllowRegistrationClick() {
    setAllowPublicRegistration(!allowPublicRegistration);
  }

  useEffect(() => {
    if (billToValue?.value !== billToValues.CUSTOMER) {
      setValue("billingRule.orgBillPeriodDays", null);
    }
    if (billToValue?.value === billToValues.PARTNER_MANAGED_BILLING) {
      setValue("essentialsGroup.productId", null);
      setValue("essentialsGroup.priceIds.0", null);
      setValue("essentialsGroup.priceIds.1", null);
      setValue("essentialsGroup.priceIds.2", null);
      setValue("essentialsGroup.priceIds.3", null);
      setValue("unlimitedGroup.productId", null);
      setValue("unlimitedGroup.priceIds.0", null);
      setValue("unlimitedGroup.priceIds.1", null);
      setValue("unlimitedGroup.priceIds.2", null);
      setValue("unlimitedGroup.priceIds.3", null);
    }
  }, [billToValue]);

  useEffect(() => {
    if (!isPrisidioBranded) return;
    setValue("logoUrl", `${PUBLIC_ASSETS_BASE_URL}/prisidio-logo.png`);
    setValue(
      "smallLogoUrl",
      `${PUBLIC_ASSETS_BASE_URL}/prisidio-logosmall-zach-test.png`
    );
    setValue("primaryColor", "#2742f5");
    setValue("pageBackgroundColor", "#f9f9ff");
  }, [isPrisidioBranded]);

  return (
    <>
      <FormField
        name="name"
        type="text"
        label="Name"
        placeholder="kozco-org"
        size="normal"
        id="organization-add-name"
        defaultValue={null}
        description="REQUIRED. This is any human-readable identifier for the organization that will be used by end-users to direct them to their organization. Use hyphen-case and lowercase letters. Only 1 hyphen allowed."
        error={errors.name}
        inputRef={register({
          required: "Please enter a name",
        })}
      />

      <FormField
        name="displayName"
        type="text"
        label="Display Name"
        placeholder="Koz Co"
        size="normal"
        id="organization-add-display-name"
        defaultValue={null}
        description="REQUIRED. This is the name that will be displayed to end-users for this organization."
        error={errors.displayName}
        inputRef={register({
          required: "Please enter a display name",
        })}
      />

      <div className="is-flex pb-4">
        <FormFieldCheckbox
          name="allowPublicRegistration"
          label="Allow Public Registration"
          description=""
          checked={allowPublicRegistration}
          onChange={handleAllowRegistrationClick}
          id="organization-add-allow-public-registration-checkbox"
          inputRef={register}
        />
      </div>

      <hr />
      <h2 className="pb-3">Brand Details</h2>

      <div className="is-flex pb-4">
        <FormFieldCheckbox
          name="isPrisidioBranded"
          label="Use Prisidio Branding"
          description=""
          checked={isPrisidioBranded}
          onChange={handlePrisidioBrandedClick}
          id="organization-add-is-prisidio-branded-checkbox"
          inputRef={register}
        />
      </div>

      <FormField
        name="logoUrl"
        type="text"
        label="Logo Url"
        placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
        size="normal"
        id="organization-add-logo-url"
        defaultValue={null}
        description="REQUIRED. This is the logo that will be displayed to end-users within this organization. The URL must use https"
        error={errors.logoUrl}
        inputRef={register({
          required: "Please enter a logo url",
        })}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="smallLogoUrl"
        type="text"
        label="Small Logo Url"
        placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
        size="normal"
        id="organization-add-small-logo-url"
        defaultValue={null}
        description="This will be displayed in mobile's vault selector. This should have an aspect ratio of 1:1. The URL must use https"
        error={errors.smallLogoUrl}
        inputRef={register({
          required: "Please enter a logo url",
        })}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="primaryColor"
        type="text"
        label="Primary Color"
        placeholder="#ff0000"
        size="normal"
        id="organization-add-primary-color"
        defaultValue={null}
        description="REQUIRED. This is the primary color that will be displayed to end-users for this organization. Enter a full Hex value, including #."
        error={errors.primaryColor}
        inputRef={register({
          required: "Please enter a primary color",
        })}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="pageBackgroundColor"
        type="text"
        label="Secondary / Background Color"
        placeholder="#000000"
        size="normal"
        id="organization-add-secondary-color"
        defaultValue={null}
        description="REQUIRED. This is the secondary / background color that will be displayed to end-users for this organization. Enter a full Hex value, including #."
        error={errors.pageBackgroundColor}
        inputRef={register({
          required: "Please enter a secondary color",
        })}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="backgroundImageUrl"
        type="text"
        label="Background Image Url"
        placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
        size="normal"
        id="organization-add-background-image-url"
        defaultValue={null}
        description="REQUIRED. This is the background image that will be displayed on the login scree. The URL must use https"
        error={errors.backgroundImageUrl}
        inputRef={register}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="slogan"
        type="text"
        label="Slogan"
        placeholder="Simple, Mobile, Secure"
        size="normal"
        id="organization-add-slogan"
        defaultValue={null}
        description="Slogan that will appear on the login screen."
        error={errors.slogan}
        inputRef={register}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="sloganBackgroundColor"
        type="text"
        label="Slogan Background Color"
        placeholder="#000000"
        size="normal"
        id="organization-add-slogan-color"
        defaultValue={null}
        description="Color that will appear behind the slogan text. Please use a value that works well with white text."
        error={errors.sloganBackgroundColor}
        inputRef={register}
        disabled={isPrisidioBranded}
      />

      <hr />
      <h2 className="pb-3">Billing</h2>

      <FormFieldSelect
        name="billingRule.billTo"
        options={billTo}
        label="Bill To"
        size="normal"
        id="organization-add-bill-to"
        description="Note: If Bill To Partner is selected, stripe-related billing fields will be disabled because stripe is irrelevant"
        error={errors?.billingRule?.billTo}
        defaultValue={billTo[0]}
        control={control}
        setValue={setValue}
      />

      <FormField
        name="billingRule.orgBillPeriodDays"
        type="number"
        min={0}
        label="Org Bill Period"
        placeholder=""
        size="normal"
        id="organization-add-billperiod-days"
        defaultValue={null}
        disabled={billToValue?.value !== billToValues.CUSTOMER}
        description="OPTIONAL. The number of days before the customer starts getting billed."
        error={errors?.billingRule?.orgBillPeriodDays}
        inputRef={register}
      />

      <hr />

      <h3 className="pb-3">{planGroup.UNLIMITED}</h3>
      <FormFieldSelect
        name="unlimitedGroup.planType"
        options={planTypeData}
        label="Plan Type"
        size="normal"
        description="Internal identifier planTypeKey"
        id="organization-add-planTypeKey-unlimited"
        error={errors?.unlimitedGroup?.planType}
        defaultValue={planTypeData[0]}
        control={control}
        setValue={setValue}
      />

      <FormField
        name="unlimitedGroup.productId"
        type="text"
        label="Subscription Product Id"
        placeholder=""
        size="normal"
        id="organization-add-product-id-unlimited"
        defaultValue=""
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        description="Stripe Product Id"
        error={errors.unlimitedGroup?.productId}
        inputRef={register}
      />

      <FormField
        name="unlimitedGroup.priceIds.0"
        type="text"
        label="Subscription Price Id #1"
        placeholder=""
        size="normal"
        id="organization-add-price-id-unlimited-0"
        defaultValue=""
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        description="Stripe Price Id"
        error={errors?.unlimitedGroup?.priceIds?.[0]}
        inputRef={register}
      />

      <FormField
        name="unlimitedGroup.priceIds.1"
        type="text"
        label="Subscription Price Id #2"
        placeholder=""
        size="normal"
        id="organization-add-price-id-unlimited-1"
        defaultValue=""
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        description="Stripe Price Id"
        error={errors?.unlimitedGroup?.priceIds?.[1]}
        inputRef={register}
      />
      <FormField
        name="unlimitedGroup.priceIds.2"
        type="text"
        label="Subscription Price Id #3"
        placeholder=""
        size="normal"
        id="organization-add-price-id-unlimited-2"
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        defaultValue=""
        description="Stripe Price Id"
        error={errors?.unlimitedGroup?.priceIds?.[2]}
        inputRef={register}
      />
      <FormField
        name="unlimitedGroup.priceIds.3"
        type="text"
        label="Subscription Price Id #4"
        placeholder=""
        size="normal"
        id="organization-add-price-id-unlimited-3"
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        defaultValue=""
        description="Stripe Price Id"
        error={errors?.unlimitedGroup?.priceIds?.[3]}
        inputRef={register}
      />

      <h3 className="pb-3">{planGroup.ESSENTIALS}</h3>
      <FormFieldSelect
        name="essentialsGroup.planType"
        options={planTypeData}
        label="Plan Type"
        description="Internal identifier planTypeKey"
        size="normal"
        id="organization-add-planTypeKey-essentials"
        error={errors?.essentialsGroup?.planType}
        defaultValue={planTypeData[0]}
        control={control}
        setValue={setValue}
      />

      <FormField
        name="essentialsGroup.productId"
        type="text"
        label="Subscription Product Id"
        placeholder=""
        size="normal"
        id="organization-add-product-id-essential"
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        defaultValue=""
        description="Stripe Product Id"
        error={errors?.essentialsGroup?.productId}
        inputRef={register}
      />

      <FormField
        name="essentialsGroup.priceIds.0"
        type="text"
        label="Subscription Price Id #1"
        placeholder=""
        size="normal"
        id="organization-add-price-id-essential-0"
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        defaultValue=""
        description="Stripe Price Id"
        error={errors?.essentialsGroup?.priceIds?.[0]}
        inputRef={register}
      />

      <FormField
        name="essentialsGroup.priceIds.1"
        type="text"
        label="Subscription Price Id #2"
        placeholder=""
        size="normal"
        id="organization-add-price-id-essential-1"
        defaultValue=""
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        description="Stripe Price Id"
        error={errors?.essentialsGroup?.priceIds?.[1]}
        inputRef={register}
      />
      <FormField
        name="essentialsGroup.priceIds.2"
        type="text"
        label="Subscription Price Id #3"
        placeholder=""
        size="normal"
        id="organization-add-price-id-essential-2"
        defaultValue=""
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        description="Stripe Price Id"
        error={errors?.essentialsGroup?.priceIds?.[2]}
        inputRef={register}
      />
      <FormField
        name="essentialsGroup.priceIds.3"
        type="text"
        label="Subscription Price Id #4"
        placeholder=""
        size="normal"
        id="organization-add-price-id-essential-3"
        defaultValue=""
        disabled={billToValue?.value === billToValues.PARTNER_MANAGED_BILLING}
        description="Stripe Price Id"
        error={errors?.essentialsGroup?.priceIds?.[3]}
        inputRef={register}
      />

      <hr />
      <h2 className="pb-3">Config</h2>

      <FormField
        name="effectiveDate"
        type="date"
        label="Effective Date"
        placeholder=""
        size="normal"
        id="organization-add-effective-date"
        defaultValue={null}
        error={errors.effectiveDate}
        inputRef={register({
          message:
            "Please enter a valid date in the following format, XX/XX/XXXX.",
        })}
      />

      <FormField
        name="endDate"
        type="date"
        label="End Date"
        placeholder=""
        size="normal"
        id="organization-add-end-date"
        defaultValue={null}
        description="OPTIONAL. What date will this organization not be available? Defaults to 12:00AM UTC of the day chosen."
        error={errors.endDate}
        inputRef={register({
          message:
            "Please enter a valid date in the following format, XX/XX/XXXX.",
        })}
      />

      <FormField
        name="customDns"
        type="text"
        label="Custom DNS"
        placeholder="kozrox.partner.vault.dev-local.prisidio.net"
        size="normal"
        id="organization-add-custom-dns"
        defaultValue={null}
        description="Custom DNS for the Org.  Will generate signup and login urls in the API. Do not include https://www. The Top level must be at least 5 characters. Ex: {mustBe5Chars}.partner.vault.dev-local.prisidio.net"
        error={errors.customDns}
        inputRef={register({
          required: "Please enter a Custom DNS value",
        })}
      />

      <FormField
        name="clientId"
        type="text"
        label="ClientId"
        placeholder=""
        size="normal"
        id="organization-add-client-id"
        defaultValue={null}
        description="Provided by Auth0 when an app is created for the organization"
        error={errors.clientId}
        inputRef={register}
      />

      <FormField
        name="authServerUrl"
        type="text"
        label="Auth Server Url"
        placeholder=""
        size="normal"
        id="organization-add-auth-server-url"
        defaultValue={null}
        description="Partner Auth Server URL"
        error={errors.authServerUrl}
        inputRef={register}
      />

      <FormField
        name="manageProfileUrl"
        type="text"
        label="Managed Profile URL"
        placeholder=""
        size="normal"
        id="organization-add-profile-url"
        defaultValue={null}
        description="If provided, users in this org will have First/Last name and email managed by the organization"
        error={errors.manageProfileUrl}
        inputRef={register}
      />

      <FormField
        name="partnerBillingPortalUrl"
        type="text"
        label="Billing Portal URL"
        placeholder=""
        size="normal"
        id="organization-add-billing-portal-url"
        defaultValue={null}
        description="If provided, users in this org will see this link on the Plan & Billing screen"
        error={errors.partnerBillingPortalUrl}
        inputRef={register}
      />

      <hr />
      <h2 className="pb-3">Partner Details</h2>

      <FormField
        name="partnerConfiguration.productIdPlanTypeMapping"
        type="text"
        label="Product Id Plan Type Mapping"
        placeholder=""
        size="normal"
        id="organization-partner-configuration-product-id-plan-type-mapping"
        defaultValue={null}
        description='OPTIONAL. The Product Id Plan Type Mapping of the Partner. This is a JSON object. Ex: {"1234": "ESSENTIALS"}'
        error={errors?.partnerConfiguration?.productIdPlanTypeMapping}
        inputRef={register}
        onClick={handlePlanTypeMappingClick}
      />

      <FormField
        name="partnerConfiguration.strategy"
        type="text"
        label="Strategy"
        placeholder=""
        size="normal"
        id="organization-partner-configuration-strategy"
        defaultValue={null}
        description="OPTIONAL. The Strategy of the Partner"
        error={errors?.partnerConfiguration?.strategy}
        inputRef={register}
      />

      <FormField
        name="partnerConfiguration.clientId"
        type="text"
        label="Client Id"
        placeholder=""
        size="normal"
        id="organization-partner-configuration-client-id"
        defaultValue={null}
        description="OPTIONAL. The Client Id of the Partner"
        error={errors?.partnerConfiguration?.clientId}
        inputRef={register}
      />

      <FormField
        name="partnerConfiguration.clientSecret"
        type="password"
        label="Client Secret"
        placeholder=""
        size="normal"
        id="organization-partner-configuration-client-secret"
        defaultValue={null}
        description="OPTIONAL. The Client Secret of the partner org."
        error={errors?.partnerConfiguration?.clientSecret}
        inputRef={register}
      />

      <FormField
        name="partnerConfiguration.tokenServerUrl"
        type="text"
        label="Token Server Url"
        placeholder=""
        size="normal"
        id="organization-partner-configuration-token-server-url"
        defaultValue={null}
        description="OPTIONAL. The Token Server Url of the Partner"
        error={errors?.partnerConfiguration?.tokenServerUrl}
        inputRef={register}
      />

      <FormField
        name="partnerConfiguration.userInfoUrl"
        type="text"
        label="User Info Url"
        placeholder=""
        size="normal"
        id="organization-partner-configuration-user-info-url"
        defaultValue={null}
        description="OPTIONAL. The User Info Url of the Partner"
        error={errors?.partnerConfiguration?.userInfoUrl}
        inputRef={register}
      />
    </>
  );
}
