import React, { useState } from "react";
import { history } from "util/router";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import { addOrganization } from "stores/organizationsStore";
import AttributeValueInputs from "./AttributeValueInputs";
import Message from "components/shared/toast/Message";
import { middleEllipsis } from "util/helpers";
import moment from "moment";
import planGroup from "enums/planGroup";
import ProductIdMappingModal from "../details/ProductIdMappingModal";

export default function OrganizationAddModal({ onDone, initialName = "" }) {
  const [editingProductIdMapping, setEditingProductIdMapping] = useState(false);
  const methods = useForm();

  const { mutate, isLoading } = useMutation(
    (data) => {
      return addOrganization(data);
    },
    {
      onSuccess: (response) => {
        toast.success(
          <Message.Success
            text={`${middleEllipsis(response.data.name)} has been added.`}
          />
        );
        history.push(`/organizations/${response?.data.orgId}`);
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function handleProductIdMappingUpdate(mapping) {
    methods.setValue(
      "partnerConfiguration.productIdPlanTypeMapping",
      JSON.stringify(mapping)
    );
    setEditingProductIdMapping(false);
  }

  function handlePlanTypeMappingClick() {
    setEditingProductIdMapping(true);
  }

  function onSubmit(data) {
    const supportedPlanTypes = [];

    if (data?.unlimitedGroup && !!data?.unlimitedGroup?.planType?.value) {
      const unlimitedPlan = {
        planGroup: planGroup.UNLIMITED,
        planTypeKey: data?.unlimitedGroup?.planType?.value,
      };
      if (
        data?.unlimitedGroup?.productId &&
        data?.unlimitedGroup?.priceIds[0]
      ) {
        unlimitedPlan.stripe = {
          productId: data?.unlimitedGroup?.productId,
          priceIds: data?.unlimitedGroup?.priceIds.filter(
            (priceId) => !!priceId
          ),
        };
      }
      supportedPlanTypes.push(unlimitedPlan);
    }
    if (data?.essentialsGroup && !!data?.essentialsGroup?.planType?.value) {
      const essentialsPlan = {
        planGroup: planGroup.ESSENTIALS,
        planTypeKey: data?.essentialsGroup?.planType?.value,
      };
      if (
        data?.essentialsGroup?.productId &&
        data?.essentialsGroup?.priceIds[0]
      ) {
        essentialsPlan.stripe = {
          productId: data?.essentialsGroup?.productId,
          priceIds: data?.essentialsGroup?.priceIds.filter(
            (priceId) => !!priceId
          ),
        };
      }
      supportedPlanTypes.push(essentialsPlan);
    }

    const partnerConfiguration =
      data?.partnerConfiguration &&
      Object.values(data.partnerConfiguration).some((v) => v !== "")
        ? {
            clientId: data?.partnerConfiguration?.clientId,
            clientSecret: data?.partnerConfiguration?.clientSecret,
            productIdPlanTypeMapping: data?.partnerConfiguration
              ?.productIdPlanTypeMapping
              ? JSON.parse(data.partnerConfiguration.productIdPlanTypeMapping)
              : null,
            strategy: data?.partnerConfiguration?.strategy,
            tokenServerUrl: data?.partnerConfiguration?.tokenServerUrl,
            userInfoUrl: data?.partnerConfiguration?.userInfoUrl,
          }
        : null;

    let org = {
      allowPublicRegistration: data?.allowPublicRegistration,
      displayName: data.displayName,
      customDns: data.customDns,
      name: data.name,
      effectiveDate: moment(data?.effectiveDate).unix().toString(),
      endDate: data.endDate ? moment(data?.endDate).unix().toString() : null,
      isPrisidioBranded: data.isPrisidioBranded,
      clientId: data.clientId || null,
      manageProfileUrl: data?.manageProfileUrl,
      partnerBillingPortalUrl: data?.partnerBillingPortalUrl,
      authServerUrl: data.authServerUrl || null,
      brandDetails: {
        logoUrl: data.logoUrl || methods.getValues("logoUrl"),
        smallLogoUrl: data.smallLogoUrl || methods.getValues("smallLogoUrl"),
        pageBackgroundColor:
          data.pageBackgroundColor || methods.getValues("pageBackgroundColor"),
        primaryColor: data.primaryColor || methods.getValues("primaryColor"),
        slogan: data.slogan || methods.getValues("slogan"),
        sloganBackgroundColor:
          data.sloganBackgroundColor ||
          methods.getValues("sloganBackgroundColor"),
        backgroundImageUrl:
          data.backgroundImageUrl || methods.getValues("backgroundImageUrl"),
      },
      billingRule: {
        billTo: data?.billingRule?.billTo?.value
          ? data?.billingRule?.billTo?.value
          : null,
        orgBillPeriodDays: !!data?.billingRule?.orgBillPeriodDays
          ? data?.billingRule?.orgBillPeriodDays
          : null,
      },
      supportedPlanTypes,
      partnerConfiguration,
    };

    mutate(org);
  }

  return (
    <>
      <Modal onClose={() => onDone()}>
        <Modal.Header title="Add Organization" onClose={() => onDone()} />
        <Modal.Body hasScroll>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <AttributeValueInputs
                initialValue={initialName}
                onPlanTypeMappingClick={handlePlanTypeMappingClick}
              />
            </form>
          </FormProvider>
        </Modal.Body>
        <Modal.Footer
          onCancel={() => onDone()}
          onSubmit={methods.handleSubmit(onSubmit)}
          submitText="Save"
          isSubmitting={isLoading}
        />
      </Modal>

      {editingProductIdMapping && (
        <ProductIdMappingModal
          onDone={handleProductIdMappingUpdate}
          onCancel={() => setEditingProductIdMapping(false)}
          productIdMapping={null}
        />
      )}
    </>
  );
}
