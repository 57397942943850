import React from "react";
import Panel from "components/shared/panel/Panel";
import moment from "moment";
import styles from "./Details.module.scss";
import TextButton from "components/shared/button/TextButton";
import { history } from "util/router";
import planGroup from "enums/planGroup";
import TextLink from "components/shared/link/TextLink";
import { middleEllipsis } from "util/helpers";

export default function OrganizationDetails({ organization, orgId }) {
  const details = [
    { label: "Display Name", value: organization?.displayName },
    { label: "Name", value: organization?.name },
    {
      label: "Public Registration Allowed",
      value: `${organization?.allowPublicRegistration}`,
      hasDivider: true,
    },
    {
      label: "orgId",
      value: organization?.orgId,
    },
    {
      label: "Auth0 orgId",
      value: organization?.idmOrgId,
      hasDivider: true,
    },
    { label: "Is Active", value: `${organization?.isActive}` },
    {
      label: "Uses Prisidio Branding",
      value: `${organization?.isPrisidioBranded}`,
      hasDivider: true,
    },
    {
      label: "Effective Date",
      value: moment.unix(organization?.effectiveDate).format("l"),
    },
    {
      label: "End Date",
      value:
        organization?.endDate && moment.unix(organization?.endDate).format("l"),
    },
    {
      label: "Custom DNS",
      value: organization?.customDns,
    },
    {
      label: "Client Id",
      value: organization?.clientId,
    },
    {
      label: "Auth Server Url",
      value: organization?.authServerUrl,
    },
    {
      label: "Managed Profile URL",
      value: organization?.manageProfileUrl,
    },
    {
      label: "Billing Portal URL",
      value: organization?.partnerBillingPortalUrl,
    },
  ];
  const brandDetails = [
    {
      label: "Logo",
      value: organization?.brandDetails?.logoUrl,
      showImage: true,
    },
    {
      label: "Small Logo",
      value: organization?.brandDetails?.smallLogoUrl,
      showImage: true,
    },
    {
      label: "Background Image",
      value: organization?.brandDetails?.backgroundImageUrl,
      showImage: true,
    },
    {
      label: "Primary Color",
      value: organization?.brandDetails?.primaryColor,
      showColor: true,
    },
    {
      label: "Page Background Color / Secondary Color",
      value: organization?.brandDetails?.pageBackgroundColor,
      showColor: true,
    },
    { label: "Slogan", value: organization?.brandDetails?.slogan },
    {
      label: "Slogan Background Color",
      value: organization?.brandDetails?.sloganBackgroundColor,
      showColor: true,
    },
  ];

  const unlimitedPlan = organization?.supportedPlanTypes?.find(
    (p) => p?.planGroup === planGroup.UNLIMITED
  );

  const essentialsPlan = organization?.supportedPlanTypes?.find(
    (p) => p?.planGroup === planGroup.ESSENTIALS
  );

  const billing = [
    {
      label: "Bill To",
      value: organization?.billingRule?.billTo,
    },
    {
      label: "Org Bill Period",
      value: organization?.billingRule?.orgBillPeriodDays,
      hasDivider: true,
    },
    {
      header: true,
      label: planGroup.UNLIMITED,
    },
    {
      label: "Plan",
      value: unlimitedPlan?.planTypeKey,
    },
    {
      label: "Subscription Product Id",
      value: unlimitedPlan?.stripe?.productId,
    },
    {
      label: "Subscription Price Id #1",
      value: unlimitedPlan?.stripe?.priceIds?.[0],
    },
    {
      label: "Subscription Price Id #2",
      value: unlimitedPlan?.stripe?.priceIds?.[1],
    },
    {
      label: "Subscription Price Id #3",
      value: unlimitedPlan?.stripe?.priceIds?.[2],
    },
    {
      label: "Subscription Price Id #4",
      value: unlimitedPlan?.stripe?.priceIds?.[3],
    },

    {
      header: true,
      label: planGroup.ESSENTIALS,
    },
    {
      label: "Plan",
      value: essentialsPlan?.planTypeKey,
    },
    {
      label: "Subscription Product Id",
      value: essentialsPlan?.stripe?.productId,
    },
    {
      label: "Subscription Price Id #1",
      value: essentialsPlan?.stripe?.priceIds?.[0],
    },
    {
      label: "Subscription Price Id #2",
      value: essentialsPlan?.stripe?.priceIds?.[1],
    },
    {
      label: "Subscription Price Id #3",
      value: essentialsPlan?.stripe?.priceIds?.[2],
    },
    {
      label: "Subscription Price Id #4",
      value: essentialsPlan?.stripe?.priceIds?.[3],
    },
  ];

  const partnerDetails = organization?.partnerConfiguration
    ? [
        {
          label: "ProductIdPlanTypeMap",
          value: organization.partnerConfiguration?.productIdPlanTypeMapping
            ? organization.partnerConfiguration.productIdPlanTypeMapping
            : "--",
          isObject: true,
        },
        {
          label: "AuthStrategy",
          value: organization.partnerConfiguration?.strategy ?? "--",
        },
        {
          label: "Client Id",
          value: organization.partnerConfiguration?.clientId ?? "--",
        },
        {
          label: "Client Secret",
          value: "******",
        },
        {
          label: "Token Server Url",
          value: organization.partnerConfiguration?.tokenServerUrl ?? "--",
          isLink: true,
        },
        {
          label: "User Info Url",
          value: organization.partnerConfiguration?.userInfoUrl ?? "--",
          isLink: true,
        },
      ]
    : [];

  function renderSimpleData(info, index) {
    if (info.header) {
      return (
        <h3 className="pb-3" key={`${info}${index}`}>
          {info.label}
        </h3>
      );
    }
    return (
      <div className="field pb-3" key={`${info}${index}`}>
        <label className="label has-text-size-normal mb-1">{info.label}</label>
        <div className="control">
          <p
            className={`has-text-karman100 is-font-size-large has-text-weight-medium ${styles.value}`}
          >
            {!!info.value ? info.value : "--"}
          </p>
        </div>
        {!!info?.hasDivider && <hr />}
      </div>
    );
  }

  function renderPartnerDetailsInfo(info) {
    if (info.isObject) {
      return Object.keys(info.value).map((key) => (
        <p
          key={key}
          className={`has-text-karman100 is-font-size-large has-text-weight-medium ${styles.value}`}
        >
          {key}: {info.value[key]}
        </p>
      ));
    }

    if (info.isLink) {
      return (
        <TextLink
          url={info?.value}
          text={middleEllipsis(info?.value, 30, 15, 15)}
          id="info-link"
          openNewWindow
        />
      );
    }

    return (
      <p
        className={`has-text-karman100 is-font-size-large has-text-weight-medium ${styles.value}`}
      >
        {info.value}
      </p>
    );
  }

  return (
    <>
      <div className="columns">
        <div className="column">
          <div className="tile is-vertical mt-3">
            <Panel>
              <article className="tile is-child is-white p-3 is-relative">
                <div className={styles.editButton}>
                  <TextButton
                    icon="edit"
                    id="organization-edit-details-btn"
                    onClick={() => {
                      history.push(`/organizations/${orgId}/edit`);
                    }}
                  />
                </div>

                <h2 className="pb-3">Org Details</h2>
                <div className="pl-3">{details.map(renderSimpleData)}</div>
              </article>
            </Panel>
          </div>
          <div className="tile is-vertical mt-3">
            <Panel>
              <article className="tile is-child is-white p-3 is-relative">
                <div className={styles.editButton}>
                  <TextButton
                    icon="edit"
                    id="organization-edit-details-btn"
                    onClick={() => {
                      history.push(`/organizations/${orgId}/edit`);
                    }}
                  />
                </div>

                <h2 className="pb-3">Billing</h2>

                <div className="pl-3">
                  {billing.map(renderSimpleData)}
                  <hr />
                </div>
              </article>
            </Panel>
          </div>
        </div>
        <div className="column">
          <div className="tile is-vertical mt-3">
            <Panel>
              <article className="tile is-child is-white p-3 is-relative">
                <div className={styles.editButton}>
                  <TextButton
                    icon="edit"
                    id="organization-edit-details-btn"
                    onClick={() => {
                      history.push(`/organizations/${orgId}/edit`);
                    }}
                  />
                </div>

                <h2 className="pb-3">Brand Details</h2>
                <div className="pl-3">
                  {brandDetails.map((info, index) => (
                    <div className="field pb-3" key={`${info}${index}`}>
                      <label className="label has-text-size-normal mb-1">
                        {info.label}
                      </label>
                      <div className="control">
                        {!info?.showImage ? (
                          <p
                            className={`has-text-karman100 is-font-size-large has-text-weight-medium ${styles.value}`}
                          >
                            {info?.showColor && (
                              <span
                                className={styles.colorBox}
                                style={{
                                  backgroundColor: info.value,
                                }}
                              ></span>
                            )}{" "}
                            {!!info.value ? info.value : "--"}
                          </p>
                        ) : (
                          <>
                            <img
                              className={styles.previewImage}
                              src={info?.value}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </article>
            </Panel>
            {!!organization?.partnerConfiguration ? (
              <div className="mt-3">
                <Panel>
                  <article className="tile is-child is-white p-3 is-relative">
                    <div className={styles.editButton}>
                      <TextButton
                        icon="edit"
                        id="organization-edit-details-btn"
                        onClick={() => {
                          history.push(`/organizations/${orgId}/edit`);
                        }}
                      />
                    </div>
                    <h2 className="pb-3">Partner Details</h2>
                    <div className="pl-3">
                      {partnerDetails.map((info, index) => (
                        <div className="field pb-3" key={`${info}${index}`}>
                          <label className="label has-text-size-normal mb-1">
                            {info.label}
                          </label>
                          <div className="control">
                            {renderPartnerDetailsInfo(info)}
                          </div>
                          {!!info?.hasDivider && <hr />}
                        </div>
                      ))}
                    </div>
                  </article>
                </Panel>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
