import React, { useEffect } from "react";
import cx from "classnames";
import styles from "./ProductIdMappingModal.module.scss";
import FormField from "components/shared/formfield/FormField";
import { useFormContext } from "react-hook-form";

export default function ProductIdMapping({ name, value, index, onRemove }) {
  const { register, errors, setValue } = useFormContext();

  useEffect(() => {
    setValue(`mappings.productIds.${index}.0`, name);
    setValue(`mappings.productIds.${index}.1`, value);
  }, [name, value, index, setValue]);

  function handleRemove(index) {
    onRemove(index);
  }

  return (
    <div
      className={styles.mappingRowContainer}
      key={`product-id-mapping-${index}`}
    >
      <FormField
        name={`mappings.productIds.${index}.0`}
        type="text"
        className={cx(`input is-normal`, styles.mappingKey)}
        placeholder="Key"
        size="normal"
        id={`product-id-mapping-key-${index}`}
        defaultValue={name}
        error={errors?.mappings?.productIds?.[index]?.[0]}
        inputRef={register({
          required: "Please enter a key",
        })}
        hasMargin={false}
      />
      <FormField
        name={`mappings.productIds.${index}.1`}
        type="text"
        className={cx(`input is-normal`, styles.mappingKey)}
        placeholder="Value"
        size="normal"
        id={`product-id-mapping-value-${index}`}
        defaultValue={value}
        error={errors?.mappings?.productIds?.[index]?.[1]}
        inputRef={register({
          required: "Please enter a value",
        })}
        hasMargin={false}
      />
      <span onClick={(e) => handleRemove(index)}>
        <span
          className={cx("material-icons-outlined", styles.removeButton)}
          id="modal-card-close"
        >
          close
        </span>
      </span>
    </div>
  );
}
