import React, { useState, useEffect, useMemo } from "react";
import Panel from "components/shared/panel/Panel";
import FormField from "components/shared/formfield/FormField";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { updateOrganization } from "stores/organizationsStore";
import { history } from "util/router.js";
import styles from "components/organizations/organization/Organization.module.scss";
import Message from "components/shared/toast/Message";
import Button from "components/shared/button/Button";
import { middleEllipsis } from "util/helpers";
import moment from "moment";
import { useDebounce } from "../../../../hooks/useDebounce";
import FormFieldCheckbox from "components/shared/form/FormFieldCheckbox";
import FormFieldSelect from "components/FormFieldSelect";
import { billTo } from "data/billToTypes";
import cx from "classnames";
import planGroup from "../../../../enums/planGroup";
import billToValues from "../../../../enums/billTo";
import planTypeData from "../../../../data/planTypeData";
import ProductIdMappingModal from "./ProductIdMappingModal";

const PUBLIC_ASSETS_BASE_URL = process.env?.REACT_APP_PUBLIC_ASSETS_URL;

export default function OrganizationDetailsEdit({ organization, orgId }) {
  const [editingProductIdMapping, setEditingProductIdMapping] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  const [logoUrlPreview, setLogoUrlPreview] = useState(
    organization?.brandDetails?.logoUrl
  );
  const [smallLogoUrlPreview, setSmallLogoUrlPreview] = useState(
    organization?.brandDetails?.smallLogoUrl
  );

  const [isPrisidioBranded, setIsPrisidioBranded] = useState(
    organization?.isPrisidioBranded
  );

  const [allowPublicRegistration, setAllowPublicRegistration] = useState(
    organization?.allowPublicRegistration
  );

  const billToValue = watch("billingRule.billTo");

  function handleIsBrandedClick() {
    setIsPrisidioBranded(!isPrisidioBranded);
  }

  function handleAllowRegistrationClick() {
    setAllowPublicRegistration(!allowPublicRegistration);
  }

  useEffect(() => {
    if (organization?.billingRule?.billTo) {
      setValue(
        "billingRule.billTo",
        billTo.find((x) => x.value === organization?.billingRule?.billTo)
      );
    }
    const unlimitedGroup = organization?.supportedPlanTypes.find(
      (planType) => planType?.planGroup === planGroup.UNLIMITED
    );
    if (!!unlimitedGroup) {
      setValue(
        "unlimitedGroup.planType",
        planTypeData.find((x) => x.value === unlimitedGroup?.planTypeKey)
      );
      setValue("unlimitedGroup.productId", unlimitedGroup?.stripe?.productId);
      setValue(
        "unlimitedGroup.priceIds.0",
        unlimitedGroup?.stripe?.priceIds?.[0]
      );
      setValue(
        "unlimitedGroup.priceIds.1",
        unlimitedGroup?.stripe?.priceIds?.[1]
      );
      setValue(
        "unlimitedGroup.priceIds.2",
        unlimitedGroup?.stripe?.priceIds?.[2]
      );
      setValue(
        "unlimitedGroup.priceIds.3",
        unlimitedGroup?.stripe?.priceIds?.[3]
      );
    }
    const essentialsGroup = organization?.supportedPlanTypes.find(
      (planType) => planType?.planGroup === planGroup.ESSENTIALS
    );
    if (!!essentialsGroup) {
      setValue(
        "essentialsGroup.planType",
        planTypeData.find((x) => x.value === essentialsGroup?.planTypeKey)
      );
      setValue("essentialsGroup.productId", essentialsGroup?.stripe?.productId);
      setValue(
        "essentialsGroup.priceIds.0",
        essentialsGroup?.stripe?.priceIds?.[0]
      );
      setValue(
        "essentialsGroup.priceIds.1",
        essentialsGroup?.stripe?.priceIds?.[1]
      );
      setValue(
        "essentialsGroup.priceIds.2",
        essentialsGroup?.stripe?.priceIds?.[2]
      );
      setValue(
        "essentialsGroup.priceIds.3",
        essentialsGroup?.stripe?.priceIds?.[3]
      );
    }

    const partnerConfiguration = organization?.partnerConfiguration;

    if (!!partnerConfiguration) {
      setValue("partnerConfiguration.clientId", partnerConfiguration.clientId);
      setValue(
        "partnerConfiguration.clientSecret",
        ""
      );
      setValue(
        "partnerConfiguration.productIdPlanTypeMapping",
        JSON.stringify(partnerConfiguration.productIdPlanTypeMapping)
      );
      setValue("partnerConfiguration.strategy", partnerConfiguration.strategy);
      setValue(
        "partnerConfiguration.tokenServerUrl",
        partnerConfiguration.tokenServerUrl
      );
      setValue(
        "partnerConfiguration.userInfoUrl",
        partnerConfiguration.userInfoUrl
      );
    }
  }, [organization]);

  function handleProductIdMappingUpdate(mappingObject) {
    setValue(
      "partnerConfiguration.productIdPlanTypeMapping",
      !!mappingObject ? JSON.stringify(mappingObject) : null
    );
    setEditingProductIdMapping(false);
  }

  useEffect(() => {
    if (billToValue?.value !== billToValues.CUSTOMER) {
      setValue("billingRule.orgBillPeriodDays", null);
    }
    if (billToValue?.value === billToValues.PARTNER_MANAGED_BILLING) {
      setValue("essentialsGroup.productId", null);
      setValue("essentialsGroup.priceIds.0", null);
      setValue("essentialsGroup.priceIds.1", null);
      setValue("essentialsGroup.priceIds.2", null);
      setValue("essentialsGroup.priceIds.3", null);
      setValue("unlimitedGroup.productId", null);
      setValue("unlimitedGroup.priceIds.0", null);
      setValue("unlimitedGroup.priceIds.1", null);
      setValue("unlimitedGroup.priceIds.2", null);
      setValue("unlimitedGroup.priceIds.3", null);
    }
  }, [billToValue]);

  const { mutate, isLoading } = useMutation(
    (data) => {
      return updateOrganization(orgId, data);
    },
    {
      onSuccess: (response) => {
        history.push(`/organizations/${orgId}`);
        toast.success(
          <Message.Success
            text={`${middleEllipsis(
              response.data.name
            )} details have been updated.`}
          />
        );
      },
      onError: (error) => {
        toast.error(`${error.response.data?.errorMessage}`);
      },
    }
  );

  function onSubmit(data) {
    const supportedPlanTypes = [];

    if (data?.unlimitedGroup && !!data?.unlimitedGroup?.planType?.value) {
      const unlimitedPlan = {
        planGroup: planGroup.UNLIMITED,
        planTypeKey: data?.unlimitedGroup?.planType?.value,
      };
      if (
        data?.unlimitedGroup?.productId &&
        data?.unlimitedGroup?.priceIds[0]
      ) {
        unlimitedPlan.stripe = {
          productId: data?.unlimitedGroup?.productId,
          priceIds: data?.unlimitedGroup?.priceIds.filter(
            (priceId) => !!priceId
          ),
        };
      }
      supportedPlanTypes.push(unlimitedPlan);
    }
    if (data?.essentialsGroup && !!data?.essentialsGroup?.planType?.value) {
      const essentialsPlan = {
        planGroup: planGroup.ESSENTIALS,
        planTypeKey: data?.essentialsGroup?.planType?.value,
      };
      if (
        data?.essentialsGroup?.productId &&
        data?.essentialsGroup?.priceIds[0]
      ) {
        essentialsPlan.stripe = {
          productId: data?.essentialsGroup?.productId,
          priceIds: data?.essentialsGroup?.priceIds.filter(
            (priceId) => !!priceId
          ),
        };
      }
      supportedPlanTypes.push(essentialsPlan);
    }

    var mergedData = {
      ...organization,
      ...data,
      clientId: data.clientId || null,
      authServerUrl: data.authServerUrl || null,
      effectiveDate: moment(data?.effectiveDate).unix().toString(),
      endDate: data.endDate ? moment(data?.endDate).unix().toString() : null,
      brandDetails: {
        logoUrl: data.brandDetails.logoUrl || getValues("brandDetails.logoUrl"),
        smallLogoUrl:
          data.brandDetails.smallLogoUrl ||
          getValues("brandDetails.smallLogoUrl"),
        pageBackgroundColor:
          data.brandDetails.pageBackgroundColor ||
          getValues("brandDetails.pageBackgroundColor"),
        primaryColor:
          data.brandDetails.primaryColor ||
          getValues("brandDetails.primaryColor"),
        slogan: data.brandDetails.slogan || getValues("brandDetails.slogan"),
        sloganBackgroundColor:
          data.brandDetails.sloganBackgroundColor ||
          getValues("brandDetails.sloganBackgroundColor"),
        backgroundImageUrl:
          data.brandDetails.backgroundImageUrl ||
          getValues("brandDetails.backgroundImageUrl"),
      },
      billingRule: {
        billTo: data?.billingRule?.billTo?.value
          ? data?.billingRule?.billTo?.value
          : null,
        orgBillPeriodDays: !!data?.billingRule?.orgBillPeriodDays
          ? data?.billingRule?.orgBillPeriodDays
          : null,
      },
      partnerConfiguration: !!data?.partnerConfiguration
        ? {
            clientId: data.partnerConfiguration.clientId,
            clientSecret: data.partnerConfiguration.clientSecret,
            productIdPlanTypeMapping: JSON.parse(
              data.partnerConfiguration.productIdPlanTypeMapping
            ),
            strategy: data.partnerConfiguration.strategy,
            tokenServerUrl: data.partnerConfiguration.tokenServerUrl,
            userInfoUrl: data.partnerConfiguration.userInfoUrl,
          }
        : null,
      supportedPlanTypes,
    };

    mutate(mergedData);
  }

  function onChangeLogoUrl(logoUrl) {
    setLogoUrlPreview(logoUrl?.target?.value);
  }

  function onChangeSmallLogoUrl(smallLogoUrl) {
    setSmallLogoUrlPreview(smallLogoUrl?.target?.value);
  }

  const debouncedLogoPreview = useDebounce(logoUrlPreview, 1000);
  const debouncedSmallLogoPreview = useDebounce(smallLogoUrlPreview, 1000);

  const productIdPlanTypeMapping = useMemo(() => {
    return organization?.partnerConfiguration?.productIdPlanTypeMapping;
  }, [organization?.partnerConfiguration?.productIdPlanTypeMapping]);

  useEffect(() => {
    if (!isPrisidioBranded) return;
    setValue("logoUrl", `${PUBLIC_ASSETS_BASE_URL}/prisidio-logo.png`);
    setValue(
      "smallLogoUrl",
      `${PUBLIC_ASSETS_BASE_URL}/prisidio-logosmall-zach-test.png`
    );
    setValue("primaryColor", "#2742f5");
    setValue("pageBackgroundColor", "#f9f9ff");
  }, [isPrisidioBranded, setValue]);

  return (
    <div className="columns">
      <form className="is-flex is-flex-grow-1" noValidate>
        <div className="column">
          <div className="tile is-vertical mt-3">
            <div className="tile">
              <Panel>
                <article className="tile is-child is-white p-3">
                  <h2 className="pb-3">Details</h2>
                  <FormField
                    autoFocus
                    name="name"
                    type="text"
                    label="Name"
                    placeholder="koz-co-org"
                    size="normal"
                    id="organization-add-name"
                    defaultValue={organization?.name}
                    description="This is any human-readable identifier for the organization that will be used by end-users to direct them to their organization. Use hyphen-case."
                    error={errors.name}
                    inputRef={register({
                      required: "Please enter a name",
                    })}
                  />

                  <FormField
                    autoFocus
                    name="displayName"
                    type="text"
                    label="Display Name"
                    placeholder="Koz Co"
                    size="normal"
                    id="organization-add-display-name"
                    defaultValue={organization?.displayName}
                    description="This is the name that will be displayed to end-users for this organization."
                    error={errors.displayName}
                    inputRef={register({
                      required: "Please enter a display name",
                    })}
                  />

                  <div className="is-flex pt-4 pb-4">
                    <FormFieldCheckbox
                      name="allowPublicRegistration"
                      label="Allow Public Registration"
                      description=""
                      checked={allowPublicRegistration}
                      onChange={handleAllowRegistrationClick}
                      id="organization-edit-allow-public-registration-checkbox"
                      inputRef={register}
                    />
                  </div>

                  <hr />

                  <h2 className="pb-3">Brand Details</h2>
                  <div className="is-flex pt-4 pb-4">
                    <FormFieldCheckbox
                      name="isPrisidioBranded"
                      label="Use Prisidio Branding"
                      description=""
                      checked={isPrisidioBranded}
                      onChange={handleIsBrandedClick}
                      id="organization-add-is-prisidio-branded-checkbox"
                      inputRef={register}
                    />
                  </div>

                  <FormField
                    autoFocus
                    name="brandDetails.logoUrl"
                    type="text"
                    label="Logo Url"
                    placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
                    size="normal"
                    id="organization-add-logo-url"
                    defaultValue={organization?.brandDetails?.logoUrl}
                    description="This is the logo that will be displayed to end-users within this organization. The URL must use https"
                    error={errors.logoUrl}
                    onChange={onChangeLogoUrl}
                    inputRef={register({
                      required: "Please enter a logo url",
                    })}
                  />

                  <FormField
                    autoFocus
                    name="brandDetails.smallLogoUrl"
                    type="text"
                    label="Small Logo Url"
                    placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
                    size="normal"
                    id="organization-add-small-logo-url"
                    defaultValue={organization?.brandDetails?.smallLogoUrl}
                    description="This will be displayed in mobile's vault selector. This should have an aspect ratio of 1:1. The URL must use https"
                    error={errors.smallLogoUrl}
                    onChange={onChangeSmallLogoUrl}
                    inputRef={register({
                      required: "Please enter a logo url",
                    })}
                  />

                  <FormField
                    autoFocus
                    name="brandDetails.primaryColor"
                    type="text"
                    label="Primary Color"
                    placeholder="#ff0000"
                    size="normal"
                    id="organization-add-primary-color"
                    defaultValue={organization?.brandDetails?.primaryColor}
                    description="This is the primary color that will be displayed to end-users for this organization. Enter a full Hex value, including #."
                    error={errors.primaryColor}
                    inputRef={register({
                      required: "Please enter a primary color",
                    })}
                  />

                  <FormField
                    name="brandDetails.pageBackgroundColor"
                    type="text"
                    label="Secondary / Background Color"
                    placeholder="#000000"
                    size="normal"
                    id="organization-add-secondary-color"
                    defaultValue={
                      organization?.brandDetails?.pageBackgroundColor
                    }
                    description="REQUIRED. This is the secondary / background color that will be displayed to end-users for this organization. Enter a full Hex value, including #."
                    error={errors.pageBackgroundColor}
                    inputRef={register({
                      required: "Please enter a secondary color",
                    })}
                  />

                  <FormField
                    name="brandDetails.backgroundImageUrl"
                    type="text"
                    label="Background Image Url"
                    placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
                    size="normal"
                    id="organization-add-background-image-url"
                    defaultValue={
                      organization?.brandDetails?.backgroundImageUrl
                    }
                    description="REQUIRED. This is the background image that will be displayed on the login scree. The URL must use https"
                    error={errors.backgroundImageUrl}
                    inputRef={register}
                  />

                  <FormField
                    autoFocus
                    name="brandDetails.slogan"
                    type="text"
                    label="Slogan"
                    placeholder="slogan"
                    size="normal"
                    id="organization-edit-slogan"
                    defaultValue={organization?.brandDetails?.slogan}
                    description=""
                    error={errors.slogan}
                    inputRef={register}
                  />

                  <FormField
                    name="brandDetails.sloganBackgroundColor"
                    type="text"
                    label="Slogan Background Color"
                    placeholder="#000000"
                    size="normal"
                    id="organization-add-slogan-color"
                    defaultValue={
                      organization?.brandDetails?.sloganBackgroundColor
                    }
                    description="REQUIRED. This is the color that will appear behind the slogan text. Please use a value that works well with white text."
                    error={errors.sloganBackgroundColor}
                    inputRef={register}
                  />

                  <hr />

                  <h2 className="pb-3">Config</h2>

                  <FormField
                    name="effectiveDate"
                    type="date"
                    label="Effective Date"
                    placeholder=""
                    size="normal"
                    id="organization-edit-effective-date"
                    defaultValue={
                      organization?.effectiveDate &&
                      moment
                        .unix(organization?.effectiveDate)
                        .format("YYYY-MM-DD")
                    }
                    error={errors.effectiveDate}
                    inputRef={register({
                      message:
                        "Please enter a valid date in the following format, XX/XX/XXXX.",
                    })}
                  />

                  <FormField
                    name="endDate"
                    type="date"
                    label="End Date"
                    placeholder=""
                    size="normal"
                    id="organization-edit-end-date"
                    defaultValue={
                      organization?.endDate &&
                      moment.unix(organization?.endDate).format("YYYY-MM-DD")
                    }
                    description="OPTIONAL. What date will this organization not be available? Defaults to 12:00AM UTC of the day chosen."
                    error={errors.endDate}
                    inputRef={register({
                      message:
                        "Please enter a valid date in the following format, XX/XX/XXXX.",
                    })}
                  />

                  <FormField
                    name="customDns"
                    type="text"
                    label="Custom DNS"
                    placeholder="kozrox.partner.vault.dev-local.prisidio.net"
                    size="normal"
                    id="organization-edit-custom-dns"
                    defaultValue={organization?.customDns}
                    description="Custom DNS for the Org.  Will generate signup and login urls in the API. Do not include https://www. The Top level must be at least 5 characters. Ex: {mustBe5Chars}.partner.vault.dev-local.prisidio.net"
                    error={errors.customDns}
                    inputRef={register({
                      required: "Please enter a Custom DNS value",
                    })}
                  />

                  <FormField
                    name="clientId"
                    type="text"
                    label="Client Id"
                    placeholder=""
                    size="normal"
                    id="organization-edit-client-id"
                    defaultValue={organization?.clientId}
                    description="Provided by Auth0 when an app is created for the organization"
                    error={errors.clientId}
                    inputRef={register}
                  />

                  <FormField
                    name="authServerUrl"
                    type="text"
                    label="Auth Server Url"
                    placeholder=""
                    size="normal"
                    id="organization-edit-auth-server-url"
                    defaultValue={organization?.authServerUrl}
                    description="Partner Auth Server URL"
                    error={errors.authServerUrl}
                    inputRef={register}
                  />

                  <FormField
                    name="manageProfileUrl"
                    type="text"
                    label="Managed Profile URL"
                    placeholder=""
                    size="normal"
                    id="organization-edit-profile-url"
                    defaultValue={organization?.manageProfileUrl}
                    description="If provided, users in this org will have First/Last name and email managed by the organization"
                    error={errors.manageProfileUrl}
                    inputRef={register}
                  />

                  <FormField
                    name="partnerBillingPortalUrl"
                    type="text"
                    label="Billing Portal URL"
                    placeholder=""
                    size="normal"
                    id="organization-edit-billing-portal-url"
                    defaultValue={organization?.partnerBillingPortalUrl}
                    description="If provided, users in this org will see this link on the Plan & Billing screen"
                    error={errors.partnerBillingPortalUrl}
                    inputRef={register}
                  />

                  <div
                    className={cx(
                      styles.sticky,
                      "field is-grouped is-flex is-justify-content-flex-end pb-4"
                    )}
                  >
                    <Button
                      color="secondary"
                      text="Cancel"
                      hasSecondaryMargin
                      id="organization-edit-cancel-btn"
                      onClick={() => history.push(`/organizations/${orgId}`)}
                      type="button"
                      disabled={isLoading}
                    />
                    <Button
                      text="Save"
                      id="organization-edit-submit-btn"
                      onClick={handleSubmit(onSubmit)}
                      isLoading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </article>
              </Panel>
            </div>
          </div>
          <div className="tile is-vertical mt-3">
            <div className="tile">
              <Panel>
                <article className="tile is-child is-white p-3">
                  <h2 className="pb-3">Billing</h2>
                  <FormFieldSelect
                    name="billingRule.billTo"
                    options={billTo}
                    label="Bill To"
                    size="normal"
                    id="organization-add-bill-to"
                    description="Note: If Bill To Partner is selected, stripe-related billing fields will be disabled because stripe is irrelevant"
                    error={errors?.billingRule?.billTo}
                    defaultValue={billTo[0]}
                    control={control}
                    setValue={setValue}
                  />

                  <FormField
                    name="billingRule.orgBillPeriodDays"
                    type="number"
                    min={0}
                    label="Org Bill Period"
                    placeholder=""
                    size="normal"
                    id="organization-add-billperiod-days"
                    defaultValue={organization?.billingRule?.orgBillPeriodDays}
                    disabled={billToValue?.value !== billToValues.CUSTOMER}
                    description="OPTIONAL. The number of days before the customer starts getting billed."
                    error={errors?.billingRule?.orgBillPeriodDays}
                    inputRef={register}
                  />

                  <hr />

                  <h3 className="pb-3">{planGroup.UNLIMITED}</h3>
                  <FormFieldSelect
                    name="unlimitedGroup.planType"
                    options={planTypeData}
                    label="Plan Type"
                    size="normal"
                    description="Internal identifier planTypeKey"
                    id="organization-add-planTypeKey-unlimited"
                    error={errors?.unlimitedGroup?.planType}
                    control={control}
                    setValue={setValue}
                  />

                  <FormField
                    name="unlimitedGroup.productId"
                    type="text"
                    label="Subscription Product Id"
                    placeholder=""
                    size="normal"
                    id="organization-add-product-id-unlimited"
                    defaultValue=""
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    description="Stripe Product Id"
                    error={errors.unlimitedGroup?.productId}
                    inputRef={register}
                  />

                  <FormField
                    name="unlimitedGroup.priceIds.0"
                    type="text"
                    label="Subscription Price Id #1"
                    placeholder=""
                    size="normal"
                    id="organization-add-price-id-unlimited-0"
                    defaultValue=""
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    description="Stripe Price Id"
                    error={errors?.unlimitedGroup?.priceIds?.[0]}
                    inputRef={register}
                  />

                  <FormField
                    name="unlimitedGroup.priceIds.1"
                    type="text"
                    label="Subscription Price Id #2"
                    placeholder=""
                    size="normal"
                    id="organization-add-price-id-unlimited-1"
                    defaultValue=""
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    description="Stripe Price Id"
                    error={errors?.unlimitedGroup?.priceIds?.[1]}
                    inputRef={register}
                  />
                  <FormField
                    name="unlimitedGroup.priceIds.2"
                    type="text"
                    label="Subscription Price Id #3"
                    placeholder=""
                    size="normal"
                    id="organization-add-price-id-unlimited-2"
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    defaultValue=""
                    description="Stripe Price Id"
                    error={errors?.unlimitedGroup?.priceIds?.[2]}
                    inputRef={register}
                  />
                  <FormField
                    name="unlimitedGroup.priceIds.3"
                    type="text"
                    label="Subscription Price Id #4"
                    placeholder=""
                    size="normal"
                    id="organization-add-price-id-unlimited-3"
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    defaultValue=""
                    description="Stripe Price Id"
                    error={errors?.unlimitedGroup?.priceIds?.[3]}
                    inputRef={register}
                  />

                  <h3 className="pb-3">{planGroup.ESSENTIALS}</h3>
                  <FormFieldSelect
                    name="essentialsGroup.planType"
                    options={planTypeData}
                    label="Plan Type"
                    description="Internal identifier planTypeKey"
                    size="normal"
                    id="organization-add-planTypeKey-essentials"
                    error={errors?.essentialsGroup?.planType}
                    defaultValue={planTypeData[0]}
                    control={control}
                    setValue={setValue}
                  />

                  <FormField
                    name="essentialsGroup.productId"
                    type="text"
                    label="Subscription Product Id"
                    placeholder=""
                    size="normal"
                    id="organization-add-product-id-essential"
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    defaultValue=""
                    description="Stripe Product Id"
                    error={errors?.essentialsGroup?.productId}
                    inputRef={register}
                  />

                  <FormField
                    name="essentialsGroup.priceIds.0"
                    type="text"
                    label="Subscription Price Id #1"
                    placeholder=""
                    size="normal"
                    id="organization-add-price-id-essential-0"
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    defaultValue=""
                    description="Stripe Price Id"
                    error={errors?.essentialsGroup?.priceIds?.[0]}
                    inputRef={register}
                  />

                  <FormField
                    name="essentialsGroup.priceIds.1"
                    type="text"
                    label="Subscription Price Id #2"
                    placeholder=""
                    size="normal"
                    id="organization-add-price-id-essential-1"
                    defaultValue=""
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    description="Stripe Price Id"
                    error={errors?.essentialsGroup?.priceIds?.[1]}
                    inputRef={register}
                  />
                  <FormField
                    name="essentialsGroup.priceIds.2"
                    type="text"
                    label="Subscription Price Id #3"
                    placeholder=""
                    size="normal"
                    id="organization-add-price-id-essential-2"
                    defaultValue=""
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    description="Stripe Price Id"
                    error={errors?.essentialsGroup?.priceIds?.[2]}
                    inputRef={register}
                  />
                  <FormField
                    name="essentialsGroup.priceIds.3"
                    type="text"
                    label="Subscription Price Id #4"
                    placeholder=""
                    size="normal"
                    id="organization-add-price-id-essential-3"
                    defaultValue=""
                    disabled={
                      billToValue?.value ===
                      billToValues.PARTNER_MANAGED_BILLING
                    }
                    description="Stripe Price Id"
                    error={errors?.essentialsGroup?.priceIds?.[3]}
                    inputRef={register}
                  />
                </article>
              </Panel>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="tile is-vertical mt-3">
            <div className="tile">
              <Panel>
                <article className="tile is-child is-white p-3">
                  <h3 className="pb-3">Brand Details Preview</h3>
                  Logo:{" "}
                  <img
                    src={debouncedLogoPreview}
                    alt="Logo Preview"
                    className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                  />
                  Small Logo:
                  <img
                    src={debouncedSmallLogoPreview}
                    alt="Small Logo Preview"
                    className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                  />
                </article>
              </Panel>
            </div>
          </div>
          {!!organization?.partnerConfiguration ? (
            <div className="tile is-vertical mt-3">
              <div className="tile">
                <Panel>
                  <article className="tile is-child is-white p-3">
                    <h2 className="pb-3">Partner Details</h2>

                    <FormField
                      name="partnerConfiguration.productIdPlanTypeMapping"
                      type="text"
                      label="Product Id Plan Type Mapping"
                      placeholder=""
                      size="normal"
                      id="organization-partner-configuration-product-id-plan-type-mapping"
                      defaultValue={
                        organization?.partnerConfiguration
                          ?.productIdPlanTypeMapping
                      }
                      description="OPTIONAL"
                      error={
                        errors?.partnerConfiguration?.productIdPlanTypeMapping
                      }
                      inputRef={register}
                      onClick={() => setEditingProductIdMapping(true)}
                    />

                    <FormField
                      name="partnerConfiguration.strategy"
                      type="text"
                      label="Strategy"
                      placeholder=""
                      size="normal"
                      id="organization-partner-configuration-strategy"
                      defaultValue={
                        organization?.partnerConfiguration?.strategy
                      }
                      description="OPTIONAL. The Strategy of the Partner"
                      error={errors?.partnerConfiguration?.strategy}
                      inputRef={register}
                    />

                    <FormField
                      name="partnerConfiguration.clientId"
                      type="text"
                      label="Client Id"
                      placeholder=""
                      size="normal"
                      id="organization-partner-configuration-client-id"
                      defaultValue={
                        organization?.partnerConfiguration?.clientId
                      }
                      description="OPTIONAL. The Client Id of the Partner"
                      error={errors?.partnerConfiguration?.clientId}
                      inputRef={register}
                    />

                    <FormField
                      name="partnerConfiguration.clientSecret"
                      type="password"
                      label="Client Secret"
                      placeholder=""
                      size="normal"
                      id="organization-partner-configuration-client-secret"
                      defaultValue={
                        organization?.partnerConfiguration?.clientSecret
                      }
                      description="OPTIONAL. The Client Secret of the partner org. Leave blank to keep the current secret unchanged."
                      error={errors?.partnerConfiguration?.clientSecret}
                      inputRef={register}
                    />

                    <FormField
                      name="partnerConfiguration.tokenServerUrl"
                      type="text"
                      label="Token Server Url"
                      placeholder=""
                      size="normal"
                      id="organization-partner-configuration-token-server-url"
                      defaultValue={
                        organization?.partnerConfiguration?.tokenServerUrl
                      }
                      description="OPTIONAL. The Token Server Url of the Partner"
                      error={errors?.partnerConfiguration?.tokenServerUrl}
                      inputRef={register}
                    />

                    <FormField
                      name="partnerConfiguration.userInfoUrl"
                      type="text"
                      label="User Info Url"
                      placeholder=""
                      size="normal"
                      id="organization-partner-configuration-user-info-url"
                      defaultValue={
                        organization?.partnerConfiguration?.userInfoUrl
                      }
                      description="OPTIONAL. The User Info Url of the Partner"
                      error={errors?.partnerConfiguration?.userInfoUrl}
                      inputRef={register}
                    />
                  </article>
                </Panel>
              </div>
            </div>
          ) : null}
        </div>
      </form>

      {editingProductIdMapping && (
        <ProductIdMappingModal
          onDone={handleProductIdMappingUpdate}
          onCancel={() => setEditingProductIdMapping(false)}
          // productIdMapping={productIdPlanTypeMapping}
          productIdMapping={
            !!getValues("partnerConfiguration.productIdPlanTypeMapping")
              ? JSON.parse(
                  getValues("partnerConfiguration.productIdPlanTypeMapping")
                )
              : null
          }
        />
      )}
    </div>
  );
}
